
.column-style {
    background: #F5F5F5;
    height: 500px;
    padding: 0 10px;
    border-radius: 10px;
}

.column-title {
    text-align: center;
    padding-top: 10px;
}

.column-title > label {
    font-size: 17pt;
}

.send-btn-div {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.send-remark-btn-div {
    margin-left: 235px;
}

.remarks-sendbtn-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 340px;
}