.two-factor-wrapper {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background: #F5F5F5;
    height: 100vh;
    display: flex;
}

.card-style {
    width: 400px;
    padding-bottom: 5px;
}