.login-wrapper {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background: #F5F5F5;
    height: 100vh;
    display: flex;
}

.login-wrapper-horizontal-align {
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    background: #F5F5F5;
    display: flex;
    margin-top: 20px;
}

.login-card {
    width: 400px;
    padding: '0.2em';
    padding-bottom: 5px;
}