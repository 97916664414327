/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
button,
html,
body,
html a {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

a,
button,
li,
span {
  -webkit-tap-highlight-color: transparent;
}

ul {
  margin: 0;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-track-x {
  height: 5px !important;
}

.scrollbar-track-y {
  width: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb-y {
  width: 5px !important;
}

.wrapper {
  position: relative; 
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1;
}

.circular-btn-progress {
  position: absolute;
  z-index: 1;
}

.circular-progress {
  position: absolute;
  z-index: 1;
  margin-left: 8px;
}

.default-circular-progress {
  position: absolute;
  z-index: 1;
}

.circular-progress-attach {
  position: absolute;
  z-index: 1;
  margin-left: 8px;
  margin-top: -2.5px;
}

.height-100-percent {
  height: 100%;
}

.card-actions {
  margin-left: auto;
  margin-right: 10px;
}

.card-actions-load-btn {
  margin-left: auto;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
}

.default-field-width {
  width: 160px;
}

.sm-field-width {
  width: 130px;
}

.suggestion {
  color: black;
}

.highlighted {
  background: lightgray;
}

.non-highlighted {
  background:white;
}

.suggestion-div {
  padding: 4px 0;
  padding-left: 3px;
  cursor: pointer;
}

.dialog-margin {
  margin-left: 150px;
}

.icon-size {
  font-size: 16pt;
}

.icon-size-small {
  font-size: 14pt;
}
.icon-btn-small {
  padding: 10px;
}

.checkbox-validation-date {
  color: gray;
  font-size: 9pt;
}

.checkbox-field-style {
  padding: 0;
  margin: 0;
  margin-right: 5px;
}

.verification-date {
  font-size: 10pt;
}

.layout-wrapper {
  padding: 10px;   
}

.layout-wrapper div::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.layout-wrapper div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.layout-wrapper div::-webkit-scrollbar-thumb {
  background: #888;
}

.layout-wrapper div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table-cell {
  font-size: 09pt;
}

.field-width-percent {
  width: 50%;
}

.filter-div {
  margin-bottom: 20px;
  margin-right: 4px;
  flex-direction: row;
  display: flex;
}

.date-to {
  margin: 0 15px;
}

.cus_link_to_profile {
  color: black;
}

.details-info {
  justify-content: space-around;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
}

.details-info > label {
  margin: 2px 0;
}

.no-style-ul {
  list-style: none;
}

.warning-background-color {
  background-color: rgb(255, 23, 68);
}

ul.sortable {
  padding: 0;
}
.sortable__element {
  z-index: 99999;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sortable__drag-handle {
  width: 18px;
  height: 11px;
  background-size: 18px 11px;
  cursor: ns-resize;
  margin-left: 15px;
}

.sortable-table {
  font-size: 14px;
}

.sortable-table__header,
.sortable-table__body__tr {
  display: flex;
  flex: 1;
}

.sortable-table__header,
.sortable-table .sortable__element {
  border-bottom: 1px solid #eeeeee;
}

.sortable-table__header {
  border-top: 1px solid #eeeeee;
  font-size: 13px;
  padding-left: 65px;
  font-weight: bold;
}

.sortable-table__body__td,
.sortable-table__header__th {
  padding: 18px 5px;
  text-align: left;
  display: flex;
  flex: 1;
  align-items: center;
  text-transform: initial;
  word-break: break-word;
}

.sortable-table__body__td:last-child ,
.sortable-table__header__th:last-child {
  flex: 0 0 100px
}

.sortable-table .sortable__drag-handle {
  width: 20px;
  max-width: 20px;
}

.sortable__element--fixed .sortable__drag-handle {
  background-size: 18px;
  cursor: default;
  margin-left: 15px;
}

.sortable__element--child {
  margin-left: 25px;
}

.sortable-table__body__tr {
  padding-left: 30px;
}

.sortable-table__body__td {
  padding: 10px 5px;
}

.button-margin-left {
  margin-left: 10px;
}