.search-bar {
    width: 315px;
    height: 30px;
    padding-left: 3px;
    padding-right: 120px;
    background-color: white;
    border-radius: 3px;
    border-bottom: 0;
}

.search-bar-progress-div {
    position: relative; 
    display: flex;
    align-items: center;
}

.search-bar-progress {
    position: absolute;
    z-index: 1;
    margin-left: 410px;
    margin-top: 1px;
}

.search-bar-settings-btn {
    position: absolute;
    z-index: 1;
    margin-left: 400px;
    margin-top: 1px;
}

.search-bar-label {
    position: absolute;
    z-index: 2;
    color: gray;
    font-size: 10pt;
    margin-left: 320px;
    margin-top: 1px;
}

.search-bar-select {
    position: absolute;
    z-index: 1;
    margin-left: 300px;
}

.search-bar-settings-icon {
   color: gray;
   font-size: 15pt;
}

.logout-btn {
    margin-top: 'auto';
    margin-bottom: 20px;
    height: 25px;
    margin-right: 10px; 
    margin-left: 10px;
}

.link {
    font-size: 10pt;
    cursor: pointer;
	border-bottom: 1px solid white;
}

.page-history-div {
    display: flex;
    flex-direction: row;
}

.page-history-slash {
    margin-left: 2px;
    margin-right: 2px;
}

.show-all-btn-div {
    display: flex; 
    flex-direction: row;
    justify-content: space-around;
	background-color: white;
}

.show-all-btn {
    margin: 10px 0;
}


